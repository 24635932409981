
.header-side-navigation .img-wrap{
    margin: 0rem;
    flex: 50%;
    padding-left:2%;
}
.header-side-navigation .img-holder{
    display: flex;
    justify-content: center;
    width:6rem;
    /* height: auto; */
}
.header-side-navigation .img-holder>img{
    max-width: 4rem;
    height: 4rem; 
    padding-bottom: 0.5rem;
}

.header-side-navigation{
    display: flex;
    flex-direction:row;
    width: 95vw;
    padding-top: 3rem;
}

.header-nav{
    flex: 10%;
    padding-left:1rem ;
}
.main11{
    flex: 85%;
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    margin-left: -1rem;
}
/* .header-logo{

}

.header-profile{

} */



