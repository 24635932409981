.customer-FAQ{
    width: 100vw;
}


.customer-FAQ .FAQ-header{
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 3rem;
    color: #707070;
    align-items: center;
    padding-top: 4rem;
    width: 100%;
}
.customer-FAQ .FAQ-header .back-button{
    text-decoration: none;
    text-align: center;
    flex:15%;
}
.customer-FAQ .FAQ-title{
    flex :85%;
    justify-content: center;
    /* text-align: center; */
    padding-right: 15%;
    padding-left: 15%;
}

.customer-FAQ .FAQ-body{
    display:flex;
    flex-direction: column;
    margin-top: 4rem;
    gap: 2.7rem;
    margin-bottom: 4rem;
}

.customer-FAQ .ques-ans{
    font-weight: 600;
    font-size: 1.6rem;
    color: #000000;
    width: 90%;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.customer-FAQ .ques{
    
}

.customer-FAQ .ans{
    
}