.verify-otp .popup-content{
    width: 40rem;
    /* height: 60%; */
    margin: 5% auto; 
}
.verify-otp .popup-body{
    padding-top: 0;
    padding-left:5%;
    padding-right: 5%;
}
.verify-otp .opt-input{
    /* justify-self: center; */
    padding-left: 8%;
    padding-top: 2%;

}

.verify-otp .opt-input input{
    border-radius: 0%;
    padding: 3% 3% 3% 3%;
    text-align: center;
    border-top:none;
    border-left:none;
    border-right: none;
    min-width: 780%;
    min-height: 7rem;

}
/* styling opt container */
.verify-otp .containerStyle{
    display: flex;
    flex-wrap: wrap;
    gap: 8%;
    display: flex;
    justify-content: center;
    width: 90%;

}

/* styling opt input */
.verify-otp .inputStyle{

border: 0.4rem solid #000000;
}


.verify-otp .popup-footer{
    /* height: 8rem; */
    /* padding-top: 5%; */
    padding-bottom: 5%;
    display: flex;
    justify-content: center;
}

.verify-otp .popup-btn-ok,
.verify-otp .popup-btn-cancel{
    background: #1506C6;   
    border-radius: 0rem;
    color: #FFFFFF;
    text-decoration: none;
    font-size: 2.3rem;
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 600;
    /* padding-left:5%;
    padding-right:5%; */
    margin-left:1rem;
    width:15rem;
}

.otp-wrap{
    display: flex;
    justify-content: center;

}
.opt-input{
    display: flex;
    justify-content: center;
    width: 90%;
}
.opt-input div{
    padding-right: 10%;
    /* font-size: 1%; */
    width: 2%;
    max-height: 7rem;
}

.invalid-otp-error{
    font-size: 1.8rem;
    font-weight: 600;
    color: red;
    text-align: center;
    width: 100%;
}
/* .loading-small-otp{
    width: 100%;
    height: 5rem;
} */
.loading-small-otp .loading-page_loading-container{
    width: 5rem;
    height: 5rem;
    border: 1rem solid transparent;
    border-top-color: #1A0BBE;
}

.loading-small-otp .loading-page-ui-main-container{
    width: 100%;
    height: 7rem;
    align-items: center;
}