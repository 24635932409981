.customer-Mypurchase{
    width: 100vw;
}


.customer-Mypurchase .Mypurchase-header{
    display: flex;
    flex-direction: row;
    color: #707070;
    align-items: center;
    padding-top: 4rem;
    width: 100%;
    font-weight: 600;
    font-size: 3rem;
}
.customer-Mypurchase .Mypurchase-header .back-button{
    text-decoration: none;
    text-align: center;
    padding-left: 5%;
}
.customer-Mypurchase .Mypurchase-title{
    justify-content: center;
    /* text-align: center; */
    padding-right: 0.5rem;
    padding-left: 10%;
    font-weight: 400;
    font-size: 1.8rem;
}

.customer-Mypurchase .Mypurchase-body{
    display:flex;
    flex-direction: column;
    margin-top: 4rem;
    gap: 2.7rem;
    margin-bottom: 4rem;
}
.customer-Mypurchase .Mypurchase-filter-type{
    font-weight: 600;
    font-size: 1.8rem;
    color: #000000;
}

.customer-Mypurchase .wrap{
    display: flex;
    justify-content: center;
    align-items: center;
}
.customer-Mypurchase .hold{
    width: 90%;
}

.customer-Mypurchase .filter{
    width: 2rem;
    height: auto;
}

.customer-Mypurchase .filter-btn-text{
    font-weight: 600;
    font-size: 1.8rem;
    color: #000000;
}

.customer-Mypurchase .filter-btn{
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    align-items: center;
    float: right;
    margin-right: 5%;
}

.customer-Mypurchase .filter-btn-div{
    flex: auto;
}