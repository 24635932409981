.customer-VeotsOTP{
    width: 100vw;
}


.customer-VeotsOTP .VeotsOTP-header{
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 3rem;
    color: #707070;
    align-items: center;
    padding-top: 4rem;
    width: 100%;
}
.customer-VeotsOTP .VeotsOTP-header .back-button{
    text-decoration: none;
    text-align: center;
    flex:15%;
}
.customer-VeotsOTP .verify-title{
    flex :85%;
    justify-content: center;
    text-align: center;
    padding-right: 15%;
}

.customer-VeotsOTP .VeotsOTP-circular{
    display: flex;
    margin-top: 4rem;
    align-content: center;
    justify-content: center;
}



.customer-VeotsOTP .ellipse {
    width: 32rem;
    height: 30rem; 
    padding-bottom: 0.5rem;
    border-radius: 100%;
    border: #707070;
    background: rgba(221, 173, 128, 0.26);
}


.customer-VeotsOTP .star-holder{
    display: flex;
    justify-content: left;
    width:7rem;
    height: auto;
}

.customer-VeotsOTP .star-holder>img {
    width: 5rem;
    height: auto; 
    padding-bottom: 0.5rem;
}

.customer-VeotsOTP .star-aligner{
    position: relative;
    top: 0rem;
    left: -30rem;
    width: 0rem;
}

.customer-VeotsOTP .star-wrap1{
    margin: 0rem;
    padding-left:2%;
    position: relative;
    top: 1rem;
    left: 0rem;
}

.customer-VeotsOTP .star-wrap2{
    margin: 0rem;
    padding-left:2%;
    position: relative;
    top: -4rem;
    left: 20rem;
}

.customer-VeotsOTP .star-wrap3{
    margin: 0rem;
    padding-left:2%;
    position: relative;
    top: 6rem;
    left: -5rem;
}

.customer-VeotsOTP .star-wrap4{
    margin: 0rem;
    padding-left:2%;
    position: relative;
    top: -4rem;
    left: 30rem;
}

.customer-VeotsOTP .star-wrap5{
    margin: 0rem;
    padding-left:2%;
    position: relative;
    top: 4.5rem;
    left: 13rem;
}

.customer-VeotsOTP .OTP-text {
    color: black;
    font-weight: 400;
    font-size: 8rem;
    text-align: center;
    position: relative;
    top: 10rem;
}

.customer-VeotsOTP .VeotsOTP-body{
    display: flex;
    flex-direction: column;
}
.customer-VeotsOTP .OTP-instruction{
    width: 70%;
    text-align: center;
    font-size: 2.2rem;
    color: #C4C4C4;
    align-self: center;
    padding-top: 0.5rem;
}

.customer-VeotsOTP .VeotsOTP-label{
    font-size: 2.5rem;
    font-weight: 600;
    color: #797979;
    text-align: center;
    margin-top: 1rem;
}

.customer-VeotsOTP .opt-input{
    /* justify-self: center; */
    padding-left: 8%;
    padding-top: 8rem;
    width: 70rem;

}

.customer-VeotsOTP .opt-input input{
    border-radius: 0%;
    padding: 3% 3% 3% 3%;
    text-align: center;
    min-width: 780%;
    min-height: 7rem;
    border-radius: 100%;
    background: #C4C4C4;
    border: none;

}
/* styling opt container */
.customer-VeotsOTP .containerStyle{
    display: flex;
    flex-wrap: wrap;
    gap: 8%;
    display: flex;
    justify-content: center;
    width: 90%;

}

.customer-VeotsOTP .VeotsOTP-footer{
    display: flex;  
    margin-top: 16rem;
}

.customer-VeotsOTP .otp-submit-btn{
    background: #C83535;
    text-align: center;
    border: none;
    padding: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 2.2rem;
    color: #FFFFFF;
    text-decoration: none;
    min-width: 100%;
}