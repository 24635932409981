.customer-login{
    min-width: 100vw;
    height: 100vh;
    background: #2114BD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
}
.customer-login .login-adj{
    margin: 10%;
    height: 100vh;
}
.customer-login .login-header{
    margin-top: 15rem;
}
.customer-login .login-header .main-title{
    font-weight: 700;
    font-size: 6.5rem;
    color: #FFFFFF;
}
.customer-login .login-header .login-note{
    font-weight: 400;
    font-size: 2.3rem;
    color: #FFFFFF;
    margin-top: 2rem;
}

.customer-login .login-body{
    background: #2114BD;
    /* width: 40rem; */
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
}
.customer-login .login-input {
    /* width: 98%; */
    height: 5rem;
    background: #EBEBEB;
    margin-top: 2.5rem;
    font-weight: 400;
    font-size: 2.5rem;
    color: rgba(0, 0, 0, 0.53);
    /* padding-left: 2rem; */
    border: none;
}
.customer-login .login-forgot-pwd{
    color: white;
    text-align: right;
    font-size: 2.5rem;
    font-weight: 400;
    text-decoration: none;
    /* margin-right: -2rem; */
    margin-top: 0.5rem;
    
}

.customer-login .login-otp{
    color: white;
    text-align: right;
    font-size: 2.7rem;
    font-style: italic;
    font-weight: 600;
    /* margin-right: -2rem; */
    margin-top: 1.5rem;
    text-decoration:underline;
    
}

.customer-login .login-btn{
    width: 18rem;
    background: #CC3939;
    border-radius: 2rem;
    text-align: center;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 26px;
    color: #FFFFFF;
    text-decoration: none;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-right: -2rem;
    margin-top: 3rem;
    align-self: flex-end;
}

.customer-login .login-footer{
    display: flex;
    flex-direction: row;
    gap:0.5rem;
    font-weight: 400;
    font-size: 2rem;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    min-width: 100vw;
    position: absolute; 
    bottom: 10%;
}
.customer-login .signUp-btn{
    font-weight: 700;
    font-size: 2rem;
    color: #FFFFFF;
    text-decoration: none;
}

.customer-login .footer-holder{
    display: flex;
    flex-direction: row;
    padding-right:20%;
}