.customer-editProfile{
    width: 100vw;
    /* height: 100vh; */
}

.customer-editProfile .header-main{
    display: flex;
    flex-direction: column;
    background: white;
    width: 100vw;
    align-items: center;
    justify-items: center;
    padding-top: 2rem;
}

.customer-editProfile .img-wrap{
    margin: 0rem;
    flex: 50%;
    padding-left:2%;
}

.customer-editProfile .img-holder{
    display: flex;
    justify-content: center;
    width:9rem;
    height: auto;
}

.customer-editProfile .img-holder>img {
    max-width: 7rem;
    max-height: 7rem; 
    padding-bottom: 0.5rem;
}

.customer-editProfile  .profile-img-holder{
    padding-top: 1rem;
    margin-bottom: -5rem;
}

.customer-editProfile .header-main .my-account{
    color: #707070;
    font-weight: 400;
    font-size: 2.5rem;
}

.customer-editProfile .profile-upload[type=file] {
    filter: alpha(opacity=0);
    opacity: 0;
    width: 7rem;
    position: relative;
    top: -5rem;
    left: 1rem;
    height: 4rem;
}

.customer-editProfile .customer-Name{
    font-weight: 400;
    font-size: 2.7rem;
    color: #000000;
    padding-bottom: 1rem;
}

.customer-editProfile .editProfile-content{
    display: flex;
    flex-direction: column;
    /* height:90vh; */
    width:100%;
}
.customer-editProfile .editProfile-header{
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 2.5rem;
    align-items: center;
    /* position: absolute; */
    top: 4rem;
    width: 100%;
}
.customer-editProfile .editProfile-header .back-button{
    text-decoration: none;
    text-align: center;
    flex:15%;
}
.customer-editProfile .editProfile-title{
    flex :85%;
    justify-content: center;
    text-align: center;
    padding-right: 15%;
}
.customer-editProfile .editProfile-body{
    display: flex;
    flex-direction: row;
    flex: 60%;
    margin-top: 2rem;
}
.customer-editProfile .adj-left{
    flex: 10%;
}
.customer-editProfile .right-content{
    flex:85%;
    display: flex;
    flex-direction: column;
}

.customer-editProfile .right-content .editProfile-title{
    font-weight: 700;
    font-size: 6.5rem;
    color: #000000;
    margin-bottom: 3rem;
}

.customer-editProfile .edit-label-input{
    width: 85%;
    border-bottom:0.3rem solid #000000;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.customer-editProfile .edit-label{
    font-size: 2rem;
    font-weight: 600;
    color: #C4C4C4;
    margin-bottom: 1rem;
}

.customer-editProfile input{
    border: none;
    font-size: 2.2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #707070;
}
.customer-editProfile.min-pwd{
    margin-top: -1.5rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 300;
    color: rgba(198, 11, 11, 0.87);
}

.customer-editProfile input[type="password"] {
    color: rgba(193, 14, 14, 0.82); 
    font-size: 2.5rem;
}


.customer-editProfile .collect-pho{
    display: flex;
    flex-direction: row;
    align-self: center;
}

.customer-editProfile .pho-label{
    border-right: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 600;
    letter-spacing: 0.08rem;
    font-size: 2.2rem;
    color: #707070;
}

.customer-editProfile .pho-input{
    font-size: 2.2rem;
    font-weight: 600;
    width:20rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left:1rem;
    letter-spacing: 0.09rem;
}

.customer-editProfile .editProfile-footer{
    display: flex;
    flex-direction: column;  
    gap: 2rem;
}

.customer-editProfile .editSubmit-btn{
    background: #C83535;
    text-align: center;
    border: none;
    padding: 2rem;
    font-size:1.9rem;
    font-weight: 700;
    color: white;
    width: 100vw;
    align-self: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    top:0rem;
}
.customer-editProfile .footer-joined-date{
    padding-top: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 13%;
}

.customer-editProfile .footer-joined-date .joined{
    font-weight: 600;
    font-size: 2rem;
    color: #C4C4C4;

}
.customer-editProfile .footer-joined-date .joined-date{
    padding-left: 1rem;
    font-weight: 600;
    font-size: 1.8rem;
    color: #707070;
}