html{
    font-size:50%;
    box-sizing:border-box;
    font-family: 'Roboto', sans-serif !important;
}

body{
    padding: 0;
    margin:0;
    
}

h1{
    margin: 0;
    padding:0;
    font-size: 5rem;
}

h2{
    margin: 0;
    padding:0;
    font-size: 4.2rem;
}

h3{
    margin: 0;
    padding:0;
    font-size: 3.5rem;
}

h4{
    margin: 0;
    padding:0;
    font-size: 2.5rem;
}

h5{
    margin: 0;
    padding:0;
    font-size: 2rem;
}

h6{
    margin: 0;
    padding:0;
    font-size: 1.5rem;
}

p{
    margin:0;
    padding:0;
    font-size:3rem;
}
