.popup-screen {
    position: fixed;
    background: #00000050;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-items: center;
  }
  
.popup-content {
    margin: 15% auto; 
    background-color: white;
    padding: 0;
    border: 0.13rem solid #888;
    width: 40rem;
    /* box-shadow: 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.2),0 0.4rem 1.3rem 0 rgba(177, 34, 34, 0.19); */
}
    
.popup-header {
    padding: 0.13rem 1rem;
    background-color: #1506C6;
    color: white;
    text-align: center;
    height: 6rem;
    display: flex;
    flex-direction: row-reverse;
}
  .popup-header .title{
    flex: 85%;
    align-self: center;
}
 
.popup-body{
    padding: 0.13rem ;
    text-align: center;
    padding-top: 3rem;
    background-color: white;
}
  
 
.popup-footer {
    padding: 0.13rem 1rem;
    background-color: white;
    color:black;
    text-align: center;
    padding-bottom: 4rem;
}
 
.close {
    color:  white;
    float: right;
    font-size:4rem;
    /* font-weight: bold; */
    text-decoration: none;
}
  
  .close:hover,
  .close:focus {
    color: #aaa;
    text-decoration: none;
    cursor: pointer;
}

.popup-footer button{
    background: #1506C6;   
    border-radius: 1rem;
    color: #FFFFFF;
    text-decoration: none;
    font-size: 2.5rem;
    border: none;
    min-width: 25%;
    /* width: 15rem; */
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    font-weight: 600;
    /* padding-left:2.5rem;
    padding-right:2.5rem; */
    margin-top: 3rem;
  }

  .help .popup-header {
    padding: 0.13rem 1rem;
    background-color: white;
    color: black;
    height: 4rem;
}

.help .popup-body {
    padding: 0.13rem ;
    text-align: center;
    padding-top: 3rem;
    width: 80%;
    padding-left: 10%;
}

.help .popup-footer {
padding-bottom: 7rem;
}

.help .close {
    color:  black;
}


