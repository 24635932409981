.customer-landingPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.customer-landingPage .page-header{
    display: flex;
    flex-direction: column;
    background: #2114BD;
    width: 100vw;
    align-items: center;
    justify-items: center;
    padding-top: 2rem;
}

.customer-landingPage .page-header .img-wrap{
    margin: 0rem;
    flex: 50%;
    padding-left:2%;
}

.customer-landingPage .page-header .img-holder{
    display: flex;
    justify-content: center;
    width:9rem;
    height: auto;
}

.customer-landingPage .page-header .img-holder>img {
    max-width: 5rem;
    max-height: 3rem; 
    padding-bottom: 0.5rem;
}

.customer-landingPage .page-header .my-account{
    color: #E5E5E5;
    font-weight: 400;
    font-size: 2.5rem;
}

.customer-landingPage  .profile-img-holder{
    padding-top: 1rem;
    margin-bottom: 0rem;
}

.customer-landingPage .profile-upload[type=file] {
    filter: alpha(opacity=0);
    opacity: 0;
    width: 7rem;
    position: relative;
    top: -5rem;
    left: 1rem;
    height: 4rem;
}

.customer-landingPage .customer-Name{
    font-weight: 400;
    font-size: 3.5rem;
    color: #FFFFFF;
    padding-bottom: 1rem;
}

.customer-landingPage .customer-pho,
.customer-landingPage .customer-email
{
    font-weight: 400;
    font-size: 2.3rem;
    color: rgba(229, 229, 229, 0.66);
    padding-bottom:2.5rem;
}
.customer-landingPage .customer-pho{
    padding-bottom: 1rem;
}

.customer-landingPage .page-body{
    display: flex;
    flex-direction: row;
    width: 100vw;
}

.customer-landingPage .adj-margin{
    flex: 5%;
}
.customer-landingPage .middle-content{
    flex:85%;
    display: flex;
    flex-direction: column;
}

.customer-landingPage .profile-option{
    display: flex;
    flex-direction: column;
    height: 11rem;
    justify-content: center;
    align-content: center;
    gap: 1.5rem;
}

.customer-landingPage .option-label{
    font-weight: 600;
    font-size: 2.5rem;
    color: #707070;
}
.customer-landingPage .option-details{
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: 300;
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.42);
    mix-blend-mode: normal;
}

.customer-landingPage .nav-button{
    text-decoration: none;
    text-align: center;
    flex:5%;
    font-weight: 400;
    font-size: 2.5rem;
    color: rgba(0, 0, 0, 0.42);
}
.customer-landingPage .navigation-details{
    flex :95%;
}


.customer-landingPage .FAQ-btn,
.customer-landingPage .help-btn{
    font-weight: 700;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.42);
    text-decoration: none;
}

.customer-landingPage .page-footer{
    height: 20rem;
    align-items: center;
    display: flex;
    justify-content: center;   
}

.customer-landingPage .logout-btn{
    background: #C83535;
    text-align: center;
    border: none;
    padding: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 2.2rem;
    color: #FFFFFF;
    text-decoration: none;
    min-width: 100%;
}