.signin-up{
    width: 100vw;
    height: 100vh;
}

.signin-up .content{
    display: flex;
    flex-direction: column;
    width:100%;
}
.signin-up .header{
    flex: 10rem;
}

.signin-up .body{
    flex: 60rem;
    display: flex;
    flex-direction: column;
    color: #707070;
    font-size: 2.6rem;
    text-align: center;

}
.signin-up .links{
    padding-top: 2rem;
}
.ok-btn{
    background: #C83535;
    text-align: center;
    border: none;
    padding: 2rem;
    font-size:2.3rem;
    font-weight: 700;
    color: white;
    text-decoration: none;
    padding-left: 4rem;
    padding-right: 4rem;
    position: relative;
    top: 15rem;
}

.signin-up .link-redirect{
    text-decoration: none;
    color: #1A0BBE;
    font-weight: 600;
    font-size:2.5rem;
}

.signin-up .footer{
    align-self: center;
    display: flex;
    position: absolute;
    bottom: 4rem;

}


.signin-up .imag-text{
    display: flex;
    flex-direction: row;
    align-self: center;
}

.signin-up .footer .img-wrap{
    margin: 0rem;
    flex: 50%;
    padding-left:2%;
    align-self: flex-end;
    justify-self: end;
}

.signin-up .footer .img-holder{
    display: flex;
    justify-content: left;
    width:7rem;
    height: auto;
}

.signin-up .footer .img-holder>img {
    max-width: 5rem;
    max-height: 3rem;
    padding-bottom: 0.5rem;
}
.signin-up .styl-text{
    flex:55%;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    text-align: right;
    color: rgb(70, 64, 64);
    width: 16rem;

}

.signin-up .btn{
    background: #C83535;
    text-align: center;
    border: none;
    padding: 2rem;
    font-size:1.5rem;
    color: white;
    width: 100vw;
    align-self: flex-end;
    margin-bottom: 4rem;
    margin-bottom: 10rem;
}


