.fakeReg .collect-pho{
    display: flex;
    flex-direction: row;
    align-self: center;
    border: 1px solid #000000;
    width:26.8rem;

}

.fakeReg .pho-label{
    background: rgba(196, 196, 196, 0.1);
    border: 1px solid #000000;
    border-bottom: none;
    border-top: none;
    border-left: none;
    font-size: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 500;
    letter-spacing: 0.09rem;
}

.fakeReg .claim-pho-input{
    font-size: 3rem;
    font-weight: 600;
    width:20rem;
    background: rgba(196, 196, 196, 0.1);
    /* border: 1px solid #000000; */
    border:none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left:1rem;
    letter-spacing: 0.09rem;
    border-radius: 0%;
}

.fakeReg .popup-body{
 display: flex;
 justify-content: center;
}