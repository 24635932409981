.claim-product .claim-product{
    width: 100vw;
    /* height: 100vh; */
}

.claim-product .claim-content{
display: flex;
flex-direction: column;
height:90vh;
width:100%;
}
.claim-product .claim-header{
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 2.5rem;
    color: #707070;
    flex: 20%;
    align-items: center;
    position: absolute;
    top: 4rem;
    width: 100%;
}
.claim-product .claim-header .back-button{
    text-decoration: none;
    text-align: center;
    flex:15%;
}
.claim-product .claim-title{
    /* flex :85%; */
    width: 100vw;
    justify-content: center;
    text-align: center;
    /* padding-right: 15%; */
}

.claim-product .claim-body{
    flex: 60%;
    display: flex;
    flex-direction: column;
    color: #707070;
    font-size: 3rem;
    justify-content: center;
}
.claim-product .collect-pho{
    display: flex;
    flex-direction: row;
    align-self: center;
    border: 1px solid #000000;
}

.claim-product .pho-label{
    background: rgba(196, 196, 196, 0.1);
    border: 1px solid #000000;
    border-bottom: none;
    border-top: none;
    border-left: none;

    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 500;
    letter-spacing: 0.09rem;
}

.claim-product .claim-pho-input{
    font-size: 3rem;
    font-weight: 600;
    width:20rem;
    background: rgba(196, 196, 196, 0.1);
    /* border: 1px solid #000000; */
    border:none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left:1rem;
    letter-spacing: 0.09rem;
    border-radius: 0%;
}
input:focus-visible {
    outline: 1px solid #000000;
    /* border-radius: 0px; */
}
  /* {
    border: 1px solid #000000;
    border-radius: 0%;
} */


.claim-product .collect-bill{
    margin-top: 2rem;
    background: rgba(196, 196, 196, 0.1);
    border: 1px solid #000000;
    display: flex;
    flex-direction: row;
    padding-top:0.5rem;
    padding-bottom: 0.5rem;
    font-size:1.3rem;
    align-self: center;
    /* min-width:40rem; */
}
.claim-product ::-webkit-file-upload-button {
    display: none;
 }

.claim-product .file-name-holder{
    width: 25rem;
    font-size: 1.6rem;
    text-align: center;
    align-self: center;
}
.claim-product .file-input{
    display: flex;
}

.claim-product .bill-upload[type=file] {
    position: absolute;
    filter: alpha(opacity=0);
    opacity: 0;
    width: 7rem;
    height: 5rem;
}

.claim-product .collect-bill .bill-upload-btn{
    font-size: 1.7rem;
    padding: 1rem;
    background: #E5E5E5;
    border: 1px solid #000000;
    font-weight: 600;
    color: #000000;
    flex:1rem;
    margin-right: 0.4rem;
}

/* .collect-bill input[type="file" i]{
    text-align: center;
    padding-top: 0.5rem;
} */

.claim-product .claim-footer{
    flex: 5rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
    gap: 2rem;
}


.claim-product .imag-text{
    display: flex;
    flex-direction: row;
    align-self: center;
}

.claim-product .claim-footer .img-wrap{
    margin: 0rem;
    flex: 50%;
    padding-left:2%;
    align-self: flex-end;
    justify-self: end;
}

.claim-product .claim-footer .img-holder{
    display: flex;
    justify-content: left;
    width:7rem;
    height: auto;
}

.claim-product .claim-footer .img-holder>img {
    max-width: 5rem;
    max-height: 3rem; 
    padding-bottom: 0.5rem;
}
.claim-product .styl-text{
    flex:55%;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    text-align: right;
    color: rgb(70, 64, 64);
    width: 16rem;

}

.claim-product .claim-btn{
    background: #C83535;
    text-align: center;
    border: none;
    padding: 2rem;
    font-size:2.2rem;
    font-weight: 700;
    color: white;
    width: 100vw;
    align-self: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
}



