.loading-page-ui-main-container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loading-page_loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border: 2rem solid transparent;
    border-top-color: #1A0BBE;
    border-radius: 50%;
    animation: loading 1500ms ease infinite ;
}
.loading-page_loading-text p{
    padding-top: 1rem;
    font-size: 4rem;
}
@keyframes loading {
    to{
        transform: rotate(1turn);
    }
}