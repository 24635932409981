.customer-VeotsCredit{
    width: 100vw;
}


.customer-VeotsCredit .VeotsCredit-header{
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 3rem;
    color: #707070;
    align-items: center;
    padding-top: 4rem;
    width: 100%;
}
.customer-VeotsCredit .VeotsCredit-header .back-button{
    text-decoration: none;
    text-align: center;
    flex:15%;
}
.customer-VeotsCredit .credits-title{
    flex :85%;
    justify-content: center;
    text-align: center;
    padding-right: 15%;
}

.customer-VeotsCredit .VeotsCredit-circular{
    display: flex;
    margin-top: 4rem;
    align-content: center;
    justify-content: center;
}



.customer-VeotsCredit .ellipse {
    width: 32rem;
    height: 30rem; 
    padding-bottom: 0.5rem;
    border-radius: 100%;
    border: #707070;
    background: rgba(221, 173, 128, 0.26);
}


.customer-VeotsCredit .star-holder{
    display: flex;
    justify-content: left;
    width:7rem;
    height: auto;
}

.customer-VeotsCredit .star-holder>img {
    width: 5rem;
    height: auto; 
    padding-bottom: 0.5rem;
}

.customer-VeotsCredit .star-aligner{
    position: relative;
    top: 0rem;
    left: -30rem;
    width: 0rem;
}

.customer-VeotsCredit .star-wrap1{
    margin: 0rem;
    padding-left:2%;
    position: relative;
    top: 1rem;
    left: 0rem;
}

.customer-VeotsCredit .star-wrap2{
    margin: 0rem;
    padding-left:2%;
    position: relative;
    top: -4rem;
    left: 20rem;
}

.customer-VeotsCredit .star-wrap3{
    margin: 0rem;
    padding-left:2%;
    position: relative;
    top: 6rem;
    left: -5rem;
}

.customer-VeotsCredit .star-wrap4{
    margin: 0rem;
    padding-left:2%;
    position: relative;
    top: -4rem;
    left: 30rem;
}

.customer-VeotsCredit .star-wrap5{
    margin: 0rem;
    padding-left:2%;
    position: relative;
    top: 4.5rem;
    left: 13rem;
}


.customer-VeotsCredit .circle-credits-title{
    font-size:2.5rem;
    position: relative;
    top: 9rem;
    text-align: center;
    color: #707070;
}

.customer-VeotsCredit .credits-text {
    color: black;
    font-weight: 400;
    font-size: 8rem;
    text-align: center;
    position: relative;
    top: 10rem;
}

.customer-VeotsCredit .VeotsCredit-body{
    display: flex;
    flex-direction: column;
}
.credits-instruction{
    width: 80%;
    text-align: center;
    font-size: 1.8rem;
    color: #C4C4C4;
    align-self: center;
    padding-top: 1rem;
}

.customer-VeotsCredit .VeotsCredit-label-input{
    width: 80%;
    border-bottom:0.3rem solid #000000;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-top: 4rem;
    align-self: center;
}

.customer-VeotsCredit .VeotsCredit-label{
    font-size: 2rem;
    font-weight: 600;
    color: #C4C4C4;
    margin-bottom: 1rem;
}

.customer-VeotsCredit .VeotsCredit-input{
    border: none;
    font-size: 2.2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #707070;
    padding-left: 1rem;
}

.customer-VeotsCredit .VeotsCredit-footer{
    display: flex;  
    margin-top: 16rem;
}

.customer-VeotsCredit .request-cash-back-btn{
    background: #C83535;
    text-align: center;
    border: none;
    padding: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 2.2rem;
    color: #FFFFFF;
    text-decoration: none;
    min-width: 100%;
}
