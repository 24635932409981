.customer-resetPassword{
 width: 100vw;
}



.customer-resetPassword .resetPwd-header{
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 2.5rem;
    color: #707070;
    align-items: center;
    padding-top: 6rem;
    width: 100%;
}
.customer-resetPassword .resetPwd-header .back-button{
    text-decoration: none;
    text-align: center;
    flex:15%;
}
.customer-resetPassword .resetPwd-title{
    flex :85%;
    justify-content: center;
    text-align: center;
    padding-right: 15%;
}

.customer-resetPassword .resetPwd-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 5rem;
    width: 100vw;
}


.customer-resetPassword .details-to-enter{
    font-size: 2rem;
    align-self: center;
    font-weight: 600;
    color: #707070;

}

.customer-resetPassword .email-phno{
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    padding-top: 6rem;
    width: 80%;
}

.customer-resetPassword .email-phno-label{
    font-weight: 600;
    font-size: 2.1rem;
    color: #797979;
    padding-bottom: 1.6rem;
}

.customer-resetPassword .email-phno-input{
    font-weight: 600;
    font-size: 2.1rem;
    color: #707070;
    background: rgba(196, 196, 196, 0.1);
    border: 0.2rem solid #000000;
    padding: 1.2rem;
    padding-left: 1rem;
}

.customer-resetPassword .resetPwd-btn{
    background: #C83535;
    text-align: center;
    border: none;
    padding: 2rem;
    font-size:2.3rem;
    font-weight: 700;
    color: white;
    text-decoration: none;
    padding-left: 8rem;
    padding-right: 8rem;
}

.customer-resetPassword .resetPwd-footer{
    width: 100vw;
    padding-top: 15rem;
    display: flex;
    align-content: center;
    justify-content: center;
}