.product-data .popup-header {
    padding: 0.13rem 1rem;
    background-color: white;
    color: black;
    height: 4rem;
}

.product-data .popup-body {
    padding: 0.13rem ;
    text-align: center;
    /* padding-top: 3rem; */
    width: 80%;
    padding-left: 10%;
    margin-bottom: 2.5rem;
}

.product-data .popup-footer {
padding-bottom: 7rem;
}

.product-data .close {
    color:  black;
}

.product-data .product-data-body{
    display: grid;    
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
}

.product-data .prod-tble-header{
    font-weight: 400;
    font-size: 1.7rem;
    color: rgba(0, 0, 0, 0.35);
    text-align: left;
}

.product-data .prod-tble-info{
    font-weight: 600;
    font-size: 1.7rem;
    color: rgba(0, 0, 0, 0.49);
    text-align: left;
    margin-bottom: 2rem;
}

.product-data .table-header{
    font-weight: 600;
    font-size: 1.8rem;
    color: #000000;
    text-align: left;
    padding-bottom: 0.7rem;
}
.product-data .warranty-info{
    font-weight: 400;
    font-size: 1.7rem;
    color: rgba(0, 0, 0, 0.35);
    text-align: left;
}