.Cashback-popup .popup-content{
    height: 60%;
    width: 90%;
    }
.Cashback-popup .popup-header {
    padding: 0.13rem 1rem;
    background-color: white;
    color: black;
    /* height: 15rem; */
    background: white;
}
.Cashback-popup .popup-content{
    background: white;
    height: 80%;
}
.Cashback-popup .popup-body {
    padding: 0.13rem ;
    text-align: center;
    width: 80%;
    padding-left: 10%;
    background: white;
    height:78%;
    display: flex;
    align-items: center;
    justify-content:center ;
}

.Cashback-popup .backgroundCoins{
    display: flex;
    /* flex-direction: row; */
    align-self: center;
    /* left: 50%; */
    position: absolute;
}

.Cashback-popup .popup-body .wrap-img{
    margin: 0rem;
    flex: 50%;
    padding-left:2%;
    align-self: flex-end;
    justify-self: center;
}

.Cashback-popup .popup-body .holder-img{
    display: flex;
    justify-content: center;
    height: auto;
}
.Cashback-popup .popup-body .holder-img>img {
    max-width: 40rem;
    max-height: 60vh; 
    padding-bottom: 0.5rem;
}

.Cashback-popup .center-text{
    width: 100%;
    text-align: center;
    /* position: relative; */
}

.Cashback-popup .Congrats{
    font-family: 'Inter';
    font-weight: 800;
    font-size: 3rem;
    color: #000000;
    padding-bottom: 3rem;
    width: 100%;
    text-align: center;
}

.Cashback-popup .credit-cashpoints{
    font-family: 'Inter';
    /* font-style: bold; */
    font-weight: 800;
    font-size: 2.6rem;
    text-align: center;
    color: #C83535;
    width: 100%;
    text-align: center;
}



.Cashback-popup .popup-footer {
    /* padding-bottom: 7rem; */
    background: white;
}
    
.Cashback-popup .close {
    color:  black;
}
.Cashback-popup .popup-footer{
    flex: 5rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}


.Cashback-popup .popup-footer .imag-text{
    display: flex;
    flex-direction: row;
    align-self: center;
}

.Cashback-popup .popup-footer .img-wrap{
    margin: 0rem;
    flex: 50%;
    padding-left:2%;
    align-self: flex-end;
    justify-self: end;
}

.Cashback-popup .popup-footer .img-holder{
    display: flex;
    justify-content: left;
    width:7rem;
    height: auto;
}
.Cashback-popup .popup-footer .img-holder>img {
    max-width: 5rem;
    max-height: 6rem; 
    padding-bottom: 0.5rem;
}

.Cashback-popup .popup-footer .footer-note-login{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    color: #101010;
}