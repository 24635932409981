.warranty-activation-popup .popup-content{
    height: 60%;
    width: 90%;
}

.warranty-activation-popup .popup-header {
    padding: 0.13rem 1rem;
    background-color: white;
    color: black;
    /* height: 15rem; */
    background: white;
}
.warranty-activation-popup .product-name-title{
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    color: #000000;
}

.warranty-activation-popup .popup-content{
    background: white;
    height: 80%;
}

.warranty-activation-popup .popup-body {
    padding: 0.13rem ;
    text-align: center;
    width: 80%;
    padding-left: 10%;
    background: white;
    height:78%;
    align-items: center;
    justify-content:center ;
}



.warranty-activation-popup .center-text{
    width: 100%;
    text-align: center;
    position: relative;
    bottom: 2rem;
}

.warranty-activation-popup .Congrats{
    font-family: 'Inter';
    font-weight: 800;
    font-style: bold;
    font-size: 3rem;
    color: #000000;
    padding-bottom: 2.6rem;
    width: 100%;
    text-align: center;
    padding-bottom: 1rem;
}

.warranty-activation-popup .credit-cashpoints{
    font-family: 'Inter';
    font-style: bold;
    font-weight: 800;
    font-size: 2.6rem;
    text-align: center;
    color: #C83535;
    width: 100%;
    text-align: center;
}



.warranty-activation-popup .popup-footer {
    /* padding-bottom: 7rem; */
    background: white;
}
    
.warranty-activation-popup .close {
    color:  black;
}
.warranty-activation-popup .popup-footer{
    flex: 5rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}


.warranty-activation-popup .popup-footer .imag-text{
    display: flex;
    flex-direction: row;
    align-self: center;
}

.warranty-activation-popup .popup-footer .img-wrap{
    margin: 0rem;
    flex: 50%;
    padding-left:2%;
    align-self: flex-end;
    justify-self: end;
}

.warranty-activation-popup .popup-footer .img-holder{
    display: flex;
    justify-content: left;
    width:7rem;
    height: auto;
}
.warranty-activation-popup .popup-footer .img-holder>img {
    max-width: 5rem;
    max-height: 3rem; 
    padding-bottom: 0.5rem;
}

.warranty-activation-popup .popup-footer .footer-note-login{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    color: #101010;
}


/* ........................................... */

.warranty-activation-popup .final-authentic_product-result-wrapper{
    position: relative;
    padding:2rem 0 ;
}
.warranty-activation-popup .final-authentic_scanned-product-image-container-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;

}
.warranty-activation-popup .final-authentic_scanned-product-image-container{
    width: 90%;
}
.warranty-activation-popup .final-authentic_scanned-product-image-container .final-authentic_scanned-image{
    max-width: 40rem;
    max-height: 35rem;
}
.warranty-activation-popup .final-authentic_final-authentic-label-conatiner-wrapper{
    position: absolute;
    top: 10%;
    left: 15%;
}
.warranty-activation-popup .final-authentic_final-authentic-label-conatiner-wrapper .final-authentic_final-authentic-label-conatiner{
    width: 45%;
}
.warranty-activation-popup .final-authentic_final-authentic-label-image{
    width: 100%; 
}