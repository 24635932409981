.confirmation-3 .popup-body1{
    display: flex;
    align-items: center;
    justify-items: center;
    height: 20rem;
    text-align: center;
}
.confirmation-3 .popup-body1 h3{
    width: 100%;
}

.confirmationFake .popup-body1{
    height: 15rem;
    display: flex;
    align-items: center;
justify-content: center;
}