.qrs-main-body-container {
    height: 100vh; height: 100svh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #212121;
}

.qrs-img-heading {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20vh 0;
}
.qrs-img-heading img {
    height: 100%;
}

.qrs-app-links-container h2 {
    text-align: center;
    color: white;
    margin: 3rem 0;
    font-weight: 300;
}

.qrs-app-links-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrs-app-links-wrapper a {
    width: 40%;
    margin: 2%;
}

.qrs-app-links-wrapper img {
    width: 100%;
    border-radius: 1rem;
}