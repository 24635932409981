.customer-register{
    width: 100vw;
    /* height: 100vh; */
}

.customer-register .signup-content{
    display: flex;
    flex-direction: column;
    height:90vh;
    width:100%;
}
.customer-register .signup-header{
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 2.5rem;
    align-items: center;
    position: absolute;
    top: 4rem;
    width: 100%;
}
.customer-register .signup-header .back-button{
    text-decoration: none;
    text-align: center;
    flex:15%;
}
.customer-register .signup-title{
    flex :85%;
    justify-content: center;
    text-align: center;
    padding-right: 15%;
}
.customer-register .signup-body{
    display: flex;
    flex-direction: row;
    flex: 60%;
    margin-top: 7rem;
}
.customer-register .adj-left{
    flex: 10%;
}
.customer-register .right-content{
    flex:85%;
    display: flex;
    flex-direction: column;
}

.customer-register .right-content .register-title{
    font-weight: 700;
    font-size: 6.5rem;
    color: #000000;
    margin-bottom: 3rem;
}

.customer-register .reg-label-input{
    width: 85%;
    border-bottom:0.3rem solid #000000;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.customer-register .reg-label{
    font-size: 2rem;
    font-weight: 600;
    color: #000000;
    margin-bottom: 1rem;
}

.customer-register input{
    border: none;
    font-size: 2.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.min-pwd{
    margin-top: -1.5rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 300;
    color: rgba(198, 11, 11, 0.87);
}

.customer-register input[type="password"] {
    color: rgba(193, 14, 14, 0.82); 
    font-size: 2.5rem;
}


.customer-register .collect-pho{
    display: flex;
    flex-direction: row;
    align-self: center;
}

.customer-register .pho-label{
    border-right: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 600;
    letter-spacing: 0.08rem;
    font-size: 2.5rem;
}

.customer-register .pho-input{
    font-size: 2.5rem;
    font-weight: 600;
    width:20rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left:1rem;
    letter-spacing: 0.09rem;
}

.customer-register .signup-footer{
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
    gap: 2rem;
}

.customer-register .signup-btn{
    background: #C83535;
    text-align: center;
    border: none;
    padding: 2rem;
    font-size:1.9rem;
    font-weight: 700;
    color: white;
    width: 100vw;
    align-self: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;
}